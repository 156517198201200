import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Container from "../../components/Container";
import Layout from "../../components/Layout";
import Separator from "../../components/Separator";

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["common"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function Trasparenza() {
    return (
        <Layout>
            <Container>
                <h1>Informativa sulla Trasparenza</h1>
                <div className="min-h-screen">
                    <h2>
                        Informazioni ex art. 1, comma 125, della legge 4 agosto
                        2017 n. 124
                    </h2>
                    <p>
                        In particolare è stato incassato nel corso
                        dell’esercizio in commento, dalla Regione Umbria
                        l’importo di euro 36.098,76, relativo al contributo PAR
                        FSC 2007/2013 - AZ.II 2.2.A Ricettività Alberghiera -
                        Bando DD 8769/2014 - Progetto di Rete: Terre d’Umbria.
                    </p>
                    <Separator />
                    <StaticImage
                        src="../../../assets/images/bando-regione.png"
                        alt="Logo regione"
                        layout="fullWidth"
                        transformOptions={{ fit: "cover" }}
                    />
                    <p>
                        Gli interventi di ristrutturazione sono stati realizzati
                        con il sostegno dei Fondi POR - FESR 2014-2020 - Asse 3
                        e Asse 8 - Azione 3.2.1 e 8.2.1 - “UMBRIAPERTA: BANDO
                        PER IL SOSTEGNO ALLE IMPRESE RICETTIVE”
                    </p>
                    <Separator />
                    <StaticImage
                        src="../../../assets/images/bando-regione.png"
                        alt="Logo regione"
                        layout="fullWidth"
                        transformOptions={{ fit: "cover" }}
                    />
                    <p>
                        Il progetto denominato “Rivisitazione dello stile
                        Francescano” è stato realizzato con il sostegno dei
                        Fondi POR - FESR 2014-2020 - Asse 3 e Asse 8 - Azione
                        3.2.1 e 8.2.1 - “UMBRIAPERTA: BANDO PER IL SOSTEGNO ALLE
                        IMPRESE RICETTIVE”
                    </p>
                </div>
            </Container>
        </Layout>
    );
}
